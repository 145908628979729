import React from "react";
const ComingSoon = () => {
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <h1>Coming Soon</h1>
      </div>
    </>
  );
};

export default ComingSoon;
